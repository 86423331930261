// 0xD3fEf5a5A911092050d7198852cE209326DDE6EB
// 0xAAB0cdC5Ba32E19A1C5A3aaaEfCA4aC414B8DDeC
// 0xDbc61A35B4eA1137968c8d499c7ccc480deDc8a7
// export const contractAddress = "0xc21EC1D8d522Af1914Ff5eBC77984dACb8eE2b5C";    
// export const contractAddress = "0x01151dED12cC19345EF21305722f550cd74B761C";
export const contractAddress = "0xea95935ba10d3f1b5cab261765fbc725094ba1d9";
export const stakingContractAddress = "0x64c113d42d0b1f29A29AdA823215Fa021f029e05";
export const blockchainTxnAddress = "https://sepolia.etherscan.io/tx";
export const INFURA_URL = "https://sepolia.infura.io/v3/23dd69b57ff54f35b7bb0983b011c832";
export const WALLET_PRIVATE_KEY = "9117135a923766df52ca4fc481310b15daf21b8716430b264083a9b38d72ab59";
export const OWNER_WALLET_ADDRESS = "0xb81798b54005170F23f08351F4C26C4e736e26C0";
