"use client"

import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import axios from "axios"
import { truncate } from "lodash"
import { toast } from "react-toastify"
import Web3 from "web3"
import ABI from "../../utils/abi"
import { contractAddress } from "../../utils/utils"
import { getFeeManagement, getTier } from "../../api/Api" // Import the API functions

function Tickets() {
  const navigate = useNavigate()
  const [tickets, setTickets] = useState([])
  const [userData, setUserData] = useState([])
  const [expandedItem, setExpandedItem] = useState(null)
  const [etherPrice, setEtherPrice] = useState(0)
  const [purchasedTickets, setPurchasedTickets] = useState([])
  const [loading, setLoading] = useState(true)

  // Add state for tier and fee data from API
  const [dataAPI, setDataAPI] = useState([])
  const [tier, setTier] = useState([])

  // Add state for tier-based distribution
  const [allHolders, setAllHolders] = useState([])
  const [holdersBalances, setHoldersBalances] = useState({})
  const [totalWeight, setTotalWeight] = useState(0)
  const [holderDetails, setHolderDetails] = useState([])
  const [totalTokensInCirculation, setTotalTokensInCirculation] = useState(0)
  const [userTokenBalance, setUserTokenBalance] = useState(0)
  const [userTier, setUserTier] = useState(null)

  // Add getData function to fetch fee management and tier data
  const getData = async () => {
    try {
      setLoading(true)
      const res = await getFeeManagement()
      const resTier = await getTier()
      console.log("API Response:", res)
      console.log("Tier Response:", resTier)

      const newData = res.data?.data || []
      const newTier = resTier.data || []

      if (JSON.stringify(newData) !== JSON.stringify(dataAPI)) {
        setDataAPI(newData)
      }
      if (JSON.stringify(newTier) !== JSON.stringify(tier)) {
        setTier(newTier)
      }
    } catch (error) {
      console.error("Error fetching data:", error)
    } finally {
      setLoading(false)
    }
  }

  function getDollarValues() {
    axios
      .get(
        `https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=bitcoin%2Cethereum&order=market_cap_desc&per_page=100&page=1&sparkline=false`,
      )
      .then((response) => {
        setEtherPrice(response.data[1].current_price)
      })
      .catch((error) => console.log(error))
  }

  const toggleExpand = (ticketId) => {
    setExpandedItem((prev) => {
      if (prev === ticketId) {
        window.scrollTo({ top: 0 })
        return null
      }
      return ticketId
    })
  }

  useEffect(() => {
    const AccountInfo = localStorage.getItem("antsInformation")
    const parsedInfo = JSON.parse(AccountInfo)
    setUserData(parsedInfo)
    fetchData()
    getDollarValues()
    getData() // Fetch fee management and tier data

    if (parsedInfo) {
      fetchPurchasedTickets(parsedInfo)
      if (parsedInfo.wallet_address) {
        fetchTokenData(parsedInfo.wallet_address)
      }
    }
  }, [])

  // Inside the determineHolderTier function, update it to ensure it never returns null or a tier with weight of 0
  const determineHolderTier = (balance) => {
    // Ensure we have a default tier even if the API data isn't loaded yet
    const defaultTiers = [
      { id: 1, name: "Tier 1", weight: 1.0, starting_token: 0, ending_token: 10000 },
      { id: 2, name: "Tier 2", weight: 1.05, starting_token: 10001, ending_token: 100000 },
      { id: 3, name: "Tier 3", weight: 1.1, starting_token: 100001, ending_token: 1000000 },
      { id: 4, name: "Tier 4", weight: 1.15, starting_token: 1000001, ending_token: 10000000 },
      { id: 5, name: "Tier 5", weight: 1.2, starting_token: 10000001, ending_token: Number.MAX_SAFE_INTEGER },
    ]

    // Convert balance to number to ensure correct comparison
    const balanceNum = Number(balance)

    // Use the tiers from API if available, otherwise use default tiers
    const tiersTouse = tier && tier.length > 0 ? tier : defaultTiers

    // Find which tier the balance falls into
    const tierStructure = [
      { min: 0, max: 10000, targetTier: 1, weight: 1.0 },
      { min: 10001, max: 100000, targetTier: 2, weight: 1.05 },
      { min: 100001, max: 1000000, targetTier: 3, weight: 1.1 },
      { min: 1000001, max: 10000000, targetTier: 4, weight: 1.15 },
      { min: 10000001, max: Number.MAX_SAFE_INTEGER, targetTier: 5, weight: 1.2 },
    ]

    // Find which tier the balance falls into
    const tierInfo = tierStructure.find((t) => balanceNum >= t.min && balanceNum <= t.max)

    if (tierInfo) {
      // Find the corresponding tier from the API data or create a default one
      const apiTier = tiersTouse.find((t) => t.id === tierInfo.targetTier) || {
        id: tierInfo.targetTier,
        name: `Tier ${tierInfo.targetTier}`,
        weight: tierInfo.weight,
        starting_token: tierInfo.min,
        ending_token: tierInfo.max,
      }

      // Ensure the weight is never 0 or undefined
      if (!apiTier.weight || apiTier.weight === 0) {
        apiTier.weight = tierInfo.weight // Use the default weight
      }

      console.log(`Token Balance: ${balanceNum} ANTS, Tier: ${tierInfo.targetTier}, Weight: ${apiTier.weight}`)
      return apiTier
    }

    // Default to Tier 5 for very large balances
    if (balanceNum > 10000000) {
      const tier5 = tiersTouse.find((t) => t.id === 5) || {
        id: 5,
        name: "Tier 5",
        weight: 1.2,
        starting_token: 10000001,
        ending_token: Number.MAX_SAFE_INTEGER,
      }

      // Ensure weight is not 0
      if (!tier5.weight || tier5.weight === 0) {
        tier5.weight = 1.2
      }

      console.log(`Token Balance: ${balanceNum} ANTS, Tier: 5, Weight: ${tier5.weight}`)
      return tier5
    }

    // Default to Tier 1 if no tier found (should not happen)
    const tier1 = tiersTouse.find((t) => t.id === 1) || {
      id: 1,
      name: "Tier 1",
      weight: 1.0,
      starting_token: 0,
      ending_token: 10000,
    }

    // Ensure weight is not 0
    if (!tier1.weight || tier1.weight === 0) {
      tier1.weight = 1.0
    }

    console.log(`No tier found for balance ${balanceNum}, defaulting to Tier 1 with weight ${tier1.weight}`)
    return tier1
  }

  // Update fetchAllHolders to use the new determineHolderTier function
  const fetchAllHolders = async () => {
    try {
      const web3 = new Web3(window.ethereum)
      const contract = new web3.eth.Contract(ABI, contractAddress)

      // Call the getAllHolders function from the smart contract
      const holders = await contract.methods.getAllHolders().call()
      setAllHolders(holders)

      // Get balances for all holders
      const balances = {}
      const holdersByTier = {
        1: 0, // Tier 1 count
        2: 0, // Tier 2 count
        3: 0, // Tier 3 count
        4: 0, // Tier 4 count
        5: 0, // Tier 5 count
      }
      const tierTotalWeights = {
        1: 0, // Tier 1 total weight
        2: 0, // Tier 2 total weight
        3: 0, // Tier 3 total weight
        4: 0, // Tier 4 total weight
        5: 0, // Tier 5 total weight
      }

      const holderDetailsList = []
      for (const holder of holders) {
        const balance = await contract.methods.balanceOf(holder).call()
        const balanceInEther = web3.utils.fromWei(balance, "ether")
        balances[holder] = balanceInEther

        // Determine holder's tier and weight using the tier from API
        const holderTier = determineHolderTier(balanceInEther)
        const weight = holderTier ? holderTier.weight : 1
        const weightedBalance = Number.parseFloat(weight) * Number.parseFloat(balanceInEther)

        // Count holders by tier
        if (holderTier) {
          holdersByTier[holderTier.id] = (holdersByTier[holderTier.id] || 0) + 1
        }

        holderDetailsList.push({
          address: holder,
          balance: balanceInEther,
          tierId: holderTier ? holderTier.id : "N/A",
          weight: weight,
          weightedBalance: weightedBalance,
        })
      }

      // After processing all holders, calculate and log the total weight by tier
      console.log("\n--- TIER WEIGHT CALCULATION ---")
      // In the fetchAllHolders function, update the calculation of total weight to ensure it's never 0
      // Modify the part where we calculate overallTotalWeight:
      let overallTotalWeight = 0
      Object.keys(holdersByTier).forEach((tierId) => {
        const tierCount = holdersByTier[tierId]
        // Get the dynamic weight from the API tier data
        const tierData = tier.find((t) => t.id === Number.parseInt(tierId))
        const tierWeight = tierData
          ? tierData.weight || // Use API weight if available
            (tierId === "1"
              ? 1.0
              : // Use default weights as fallback
                tierId === "2"
                ? 1.05
                : tierId === "3"
                  ? 1.1
                  : tierId === "4"
                    ? 1.15
                    : 1.2)
          : tierId === "1"
            ? 1.0
            : // Use default weights if no API data
              tierId === "2"
              ? 1.05
              : tierId === "3"
                ? 1.1
                : tierId === "4"
                  ? 1.15
                  : 1.2

        // Ensure weight is never 0
        const safeWeight = tierWeight || 1.0

        // Ensure count is never 0
        const safeCount = tierCount || 0

        const tierTotalWeight = safeCount * safeWeight
        tierTotalWeights[tierId] = tierTotalWeight
        overallTotalWeight += tierTotalWeight

        console.log(
          `Tier ${tierId}: ${safeCount} holders × ${safeWeight} weight = ${tierTotalWeight.toFixed(2)} total weight`,
        )
      })

      // Ensure we have a minimum total weight
      if (overallTotalWeight <= 0) {
        console.log("Total weight is 0 or negative, setting a minimum value to avoid division by zero")
        overallTotalWeight = 1 // Set a minimum value to avoid division by zero
      }

      console.log(`Overall Total Weight: ${overallTotalWeight.toFixed(2)}`)
      console.log("--- END TIER WEIGHT CALCULATION ---\n")

      setHoldersBalances(balances)
      setTotalWeight(overallTotalWeight) // Use the overall total weight based on holder count
      setHolderDetails(holderDetailsList)

      console.log("All Holders:", holders)
      console.log("Holders Balances:", balances)
      console.log("Holders by Tier:", holdersByTier)
      console.log("Tier Total Weights:", tierTotalWeights)
      console.log("Total Weight:", overallTotalWeight)
      console.log("Holder Details (with Tiers):", holderDetailsList)
    } catch (error) {
      console.error("Error fetching all holders:", error)
    }
  }

  const fetchTokenData = async (userWalletAddress) => {
    try {
      const web3 = new Web3(window.ethereum)
      const contract = new web3.eth.Contract(ABI, contractAddress)

      // Get total supply
      const totalSupply = await contract.methods.totalSupply().call()
      setTotalTokensInCirculation(web3.utils.fromWei(totalSupply, "ether"))

      // Get user balance if wallet address is available
      if (userWalletAddress) {
        const balance = await contract.methods.balanceOf(userWalletAddress).call()
        const balanceInEther = web3.utils.fromWei(balance, "ether")
        setUserTokenBalance(balanceInEther)

        // Determine user's tier based on balance using the tier from API
        const userTierInfo = determineHolderTier(balanceInEther)
        setUserTier(userTierInfo)

        console.log("User Token Balance:", balanceInEther)
        console.log("User Tier:", userTierInfo)
      }

      // Fetch all holders after getting token data
      await fetchAllHolders()
    } catch (error) {
      console.error("Error fetching token data:", error)
    }
  }

  // Update the calculateRewardDistribution function to handle the case where totalWeight is 0
  const calculateRewardDistribution = (feeAmount) => {
    if (!allHolders.length) {
      console.log("No holders found, returning empty rewards object")
      return {}
    }

    // Ensure totalWeight is never 0 to avoid division by zero
    const safeTotalWeight = totalWeight > 0 ? totalWeight : 1

    // Calculate the share per unit weight
    const sharePerUnitWeight = Number.parseFloat(feeAmount) / safeTotalWeight
    console.log("\n--- REWARD DISTRIBUTION CALCULATION ---")
    console.log(`ETH to distribute: ${feeAmount} ETH`)
    console.log(`Total Weight: ${safeTotalWeight.toFixed(2)}`)
    console.log(`Share Per Unit Weight: ${sharePerUnitWeight.toFixed(8)} ETH per unit weight`)

    // Calculate rewards for each holder based on their tier weight
    const rewards = {}

    // Group rewards by tier for summary
    const tierRewards = {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
    }

    for (const holder of allHolders) {
      const balance = holdersBalances[holder]
      // Use the tier data from API
      const holderTier = determineHolderTier(balance)

      if (holderTier) {
        // Ensure weight is never 0
        const weight = holderTier.weight || 1.0

        const holderReward = weight * sharePerUnitWeight
        rewards[holder] = holderReward

        // Add to tier total
        tierRewards[holderTier.id] = (tierRewards[holderTier.id] || 0) + holderReward

        // Log example calculations for a few holders
        if (Object.keys(rewards).length <= 3) {
          console.log(
            `Example: Holder with ${Number.parseFloat(balance).toFixed(2)} tokens in Tier ${holderTier.id} (weight ${weight}x)`,
          )
          console.log(`Reward = ${weight} × ${sharePerUnitWeight.toFixed(8)} = ${holderReward.toFixed(8)} ETH`)
        }
      }
    }

    // Log tier reward totals
    console.log("\nRewards by Tier:")
    let totalRewardSum = 0
    Object.keys(tierRewards).forEach((tierId) => {
      const tierReward = tierRewards[tierId]
      totalRewardSum += tierReward
      console.log(`Tier ${tierId}: ${tierReward.toFixed(8)} ETH`)
    })
    console.log(`Total Rewards: ${totalRewardSum.toFixed(8)} ETH`)
    console.log("--- END REWARD DISTRIBUTION CALCULATION ---\n")

    return rewards
  }

  const fetchData = () => {
    setLoading(true)
    axios
      .get(`${global.backendUrl}/tickets`)
      .then((res) => {
        setTickets(res.data)
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
      })
  }

  const fetchPurchasedTickets = (parsedInfo) => {
    setLoading(true)
    axios
      .get(`${global.backendUrl}/tickets/purchase/${parsedInfo?.uid}`)
      .then((res) => {
        setPurchasedTickets(res.data)
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
      })
  }

  // Update purchaseTicket function to use the API data for fees and tiers
  const purchaseTicket = async (ticketId, weightage, ticketPrice, ticketReturnValue) => {
    if (purchasedTickets.length > 0) {
      toast.info("You've already purchased a ticket.")
      return
    }
    const ticketPriceInEth = ticketPrice / etherPrice

    const tokensToAssign = Number.parseInt(weightage)

    if (!userData) {
      navigate("/login")
      return
    }
    axios.get(`${global.backendUrl}/walletassocaite`).then(async (resAssociation) => {
      if (resAssociation.data.length < 1) {
        toast.error("Enter amount greater than zero!", {
          position: toast.POSITION.TOP_RIGHT,
        })
        return
      }
      const wallets = [],
        percentages = []
      resAssociation.data.forEach((element) => {
        wallets.push(element.wallet_address)
        percentages.push(Number.parseFloat(element.wallet_percentage))
      })

      const web3 = new Web3(window.ethereum)
      await window.ethereum.enable()
      const accounts = await web3.eth.getAccounts()
      const account = accounts[0]
      const owner = account

      let weiValue = 0
      weiValue = Web3.utils.toWei(ticketPriceInEth.toString(), "ether")

      // Get fee percentages from API data
      const feePercentage = dataAPI.length > 0 ? dataAPI[0].feePercentage : 10 // Default to 10% if no data
      const holderPercentage = dataAPI.length > 0 ? dataAPI[0].holderPercentage : 60 // Default to 60% if no data
      const platformPercentage = 100 - holderPercentage // Calculate platform percentage

      console.log("Fee Percentage:", feePercentage)
      console.log("ANTS Holders Percentage:", holderPercentage)
      console.log("Platform Percentage:", platformPercentage)

      // Calculate actual amounts for display/logging
      const ticketAmount = Number.parseFloat(ticketPrice)
      const feeAmount = ((ticketAmount * feePercentage) / 100).toFixed(5)
      const holderAmount = ((Number.parseFloat(feeAmount) * holderPercentage) / 100).toFixed(5)
      const platformAmount = ((Number.parseFloat(feeAmount) * platformPercentage) / 100).toFixed(5)

      console.log("Ticket price:", ticketAmount, "USD")
      console.log("Fee amount:", feeAmount, "USD")
      console.log("ANTS Holders amount:", holderAmount, "USD")
      console.log("Platform amount:", platformAmount, "USD")

      // Calculate reward distribution based on the new ANTS tiers and weights
      const rewardDistribution = calculateRewardDistribution(holderAmount)

      // Add detailed console logging for ticket purchase
      console.log("\n=== TICKET PURCHASE TIER DISTRIBUTION DETAILS ===")
      console.log(`Ticket: ${ticketId} - ${tickets.find((t) => t.ticket_id === ticketId)?.ticket_name}`)
      console.log(`Ticket Price: $${ticketPrice} (${ticketPriceInEth.toFixed(8)} ETH)`)
      console.log(`Mintback: ${ticketReturnValue}%`)
      console.log(`Tokens to Assign: ${tokensToAssign} ANTS`)
      console.log("\n--- TOKEN CIRCULATION DATA ---")
      console.log(`Total Tokens in Circulation: ${Number(totalTokensInCirculation).toLocaleString()} ANTS`)
      console.log(`User Token Balance: ${Number(userTokenBalance).toLocaleString()} ANTS`)
      console.log(`User Tier: ${userTier ? `Tier ${userTier.id} (Weight: ${userTier.weight}x)` : "Not determined"}`)

      console.log("\n--- FEE CALCULATION ---")
      console.log(`Fee Percentage: ${feePercentage}%`)
      console.log(`Holder Percentage: ${holderPercentage}%`)
      console.log(`Platform Percentage: ${platformPercentage}%`)
      console.log(`Ticket Price: $${ticketAmount}`)
      console.log(`Fee Amount: $${feeAmount} (${(feeAmount / etherPrice).toFixed(8)} ETH)`)
      console.log(`Amount for ANTS Holders: $${holderAmount} (${(holderAmount / etherPrice).toFixed(8)} ETH)`)
      console.log(`Amount for Platform: $${platformAmount} (${(platformAmount / etherPrice).toFixed(8)} ETH)`)

      console.log("\n--- HOLDER DISTRIBUTION SUMMARY ---")
      console.log(`Total Holders: ${allHolders.length}`)
      console.log(`Total Weight: ${totalWeight.toFixed(2)}`)
      console.log(`Share Per Unit Weight: ${(Number(holderAmount) / totalWeight / etherPrice).toFixed(8)} ETH`)

      // Group holders by tier
      const holdersByTier = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 }
      const tierTotalBalances = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 }
      const tierTotalWeights = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 }
      const tierTotalRewards = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 }

      holderDetails.forEach((holder) => {
        if (holder.tierId && holder.tierId !== "N/A") {
          const tierId = Number(holder.tierId)
          holdersByTier[tierId] = (holdersByTier[tierId] || 0) + 1
          tierTotalBalances[tierId] += Number(holder.balance)
          tierTotalWeights[tierId] += holder.weightedBalance

          // Add reward to tier total if this holder has a reward
          if (rewardDistribution[holder.address]) {
            tierTotalRewards[tierId] += rewardDistribution[holder.address]
          }
        }
      })

      console.log("\n--- TIER DISTRIBUTION ---")
      Object.keys(holdersByTier).forEach((tierId) => {
        if (holdersByTier[tierId] > 0) {
          const tierData = tier.find((t) => t.id === Number(tierId))
          const tierWeight = tierData
            ? tierData.weight
            : tierId === "1"
              ? 1.0
              : tierId === "2"
                ? 1.05
                : tierId === "3"
                  ? 1.1
                  : tierId === "4"
                    ? 1.15
                    : 1.2

          console.log(`Tier ${tierId} (Weight: ${tierWeight}x):`)
          console.log(
            `  Holders: ${holdersByTier[tierId]} (${((holdersByTier[tierId] / allHolders.length) * 100).toFixed(2)}% of total)`,
          )
          console.log(`  Total Balance: ${tierTotalBalances[tierId].toFixed(2)} ANTS`)
          console.log(
            `  Total Weight: ${tierTotalWeights[tierId].toFixed(2)} (${((tierTotalWeights[tierId] / totalWeight) * 100).toFixed(2)}% of total)`,
          )
          console.log(
            `  Total Rewards: ${tierTotalRewards[tierId].toFixed(8)} ETH (${((tierTotalRewards[tierId] / Number(holderAmount)) * etherPrice * 100).toFixed(2)}% of holder amount)`,
          )
        }
      })

      console.log("\n--- SAMPLE HOLDER REWARDS ---")
      // Show top 5 holders by reward amount
      const topHolders = [...allHolders]
        .map((address) => ({
          address,
          balance: holdersBalances[address],
          tier: determineHolderTier(holdersBalances[address]),
          reward: rewardDistribution[address] || 0,
        }))
        .sort((a, b) => b.reward - a.reward)
        .slice(0, 5)

      topHolders.forEach((holder, index) => {
        console.log(
          `Holder ${index + 1}: ${holder.address.substring(0, 6)}...${holder.address.substring(holder.address.length - 4)}`,
        )
        console.log(`  Balance: ${Number(holder.balance).toFixed(2)} ANTS`)
        console.log(
          `  Tier: ${holder.tier ? holder.tier.id : "N/A"} (Weight: ${holder.tier ? holder.tier.weight : "N/A"}x)`,
        )
        console.log(
          `  Reward: ${holder.reward.toFixed(8)} ETH (${((holder.reward / Number(holderAmount)) * etherPrice * 100).toFixed(4)}% of holder amount)`,
        )
      })

      console.log("=== END TICKET PURCHASE TIER DISTRIBUTION DETAILS ===\n")

      // Inside purchaseTicket, update the tierDistributionData to use tier from API
      const tierDistributionData = {
        totalTokensInCirculation: totalTokensInCirculation,
        userTokenBalance: userTokenBalance,
        userTier: userTier ? userTier.id : 0,
        tiersList: tier, // Use the tier data from API
        allHolders: allHolders,
        holdersBalances: holdersBalances,
        totalWeight: totalWeight,
        rewardDistribution: rewardDistribution,
        ticketData: {
          ticketId,
          ticketPrice,
          ticketReturnValue,
          weightage,
        },
      }

      console.log("Tier Distribution Data for Ticket Purchase:", tierDistributionData)

      const contract = new web3.eth.Contract(ABI, contractAddress)
      const gasPrice = await web3.eth.getGasPrice()

      contract.methods
        .purchaseTicket(
          userData?.wallet_address,
          tokensToAssign,
          wallets,
          percentages,
          JSON.stringify(tierDistributionData), // Pass the tier distribution data to the contract
        )
        .send({
          from: owner,
          gas: 2000000,
          gasPrice: gasPrice,
          value: weiValue,
        })
        .then(async (result) => {
          // Store reward distribution data for each holder
          for (const holder of allHolders) {
            if (rewardDistribution[holder]) {
              const rewardAmount = rewardDistribution[holder]

              // Create reward claim record
              const rewardData = {
                holder_address: holder,
                reward_amount: rewardAmount,
                ticket_id: ticketId,
                transaction_hash: result.transactionHash,
                type: "ticket"
              }

              // Save to database
              try {
                await axios.post(`${global.backendUrl}/claim_reward/insert`, rewardData)
                console.log(`Reward saved for holder ${holder}`)
              } catch (error) {
                console.error(`Error saving reward for holder ${holder}:`, error)
              }
            }
          }

          const formData = {
            user_id: userData.uid,
            ticket_id: ticketId,
            ticket_purchase_weightage: tokensToAssign,
            tier_distribution: JSON.stringify(tierDistributionData),
          }
          axios
            .post(`${global.backendUrl}/tickets/purchase`, formData)
            .then((res) => {
              toast.success("Ticket purchased successfully")
              // Refresh purchased tickets
              fetchPurchasedTickets(userData)
            })
            .catch((error) => {
              console.log(error)
            })
        })
        .catch((error) => console.log(error))
    })
  }

  console.log(tickets)

  return (
    <section className="tf-help-center">
      <div className="tf-container">
        <div className="row justify-content-center">
          <div className="col-lg-12 col-md-12">
            {loading ? (
              <div className="loading-container">
                <span className="loader"></span>
              </div>
            ) : (
              <>
                <div className="tf-heading style-5">
                  <h4 className="heading">Be a part of Governance</h4>
                </div>
                <div className="tf-create-wrap">
                  {tickets.map((item) => {
                    return (
                      <div className="col-lg-4 col-md-6" key={item.ticket_id}>
                        <div
                          key={item.ticket_id}
                          className={`tf-create style-2 ${expandedItem === item.ticket_id ? "expanded" : ""}`}
                        >
                          <h6 className="title">{item.ticket_name}</h6>
                          <ul className="key-value-list">
                            <li>
                              <span className="key">{"Ticket Price"}</span>
                              <span className="value">
                                <b>${item.ticket_price}</b>
                              </span>
                            </li>
                            <li>
                              <span className="key">{"Mintback on every transaction"}</span>
                              <span className="value">
                                <b>{item.ticket_return}%</b>
                              </span>
                            </li>
                            <li>
                              <span className="key">{"Instant Mintback"}</span>
                              <span className="value">
                                <b>~{item.ticket_weightage} ANTS Token</b>
                              </span>
                            </li>
                          </ul>
                          <p className="content" style={{ textAlign: "start", fontSize: "16px" }}>
                            {expandedItem === item.ticket_id ? (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: item.ticket_description,
                                }}
                              />
                            ) : (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: truncate(item.ticket_description, {
                                    length: 60,
                                  }),
                                }}
                              />
                            )}
                          </p>
                          <div className="button">
                            <div className="tf-button" onClick={() => toggleExpand(item.ticket_id)}>
                              {expandedItem === item.ticket_id ? "View less" : "View more"}
                              <i className="fal fa-long-arrow-right"></i>
                            </div>
                            <div
                              className="tf-button button-background-blue ml-2"
                              onClick={() => {
                                purchaseTicket(
                                  item.ticket_id,
                                  item.ticket_weightage,
                                  item.ticket_price,
                                  item.ticket_return,
                                )
                              }}
                            >
                              Purchase
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Tickets

