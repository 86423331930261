import axios from 'axios'

// const baseUrl = 'http://localhost:5000';
const baseUrl = 'https://antsprotocoladminapi.unialsolutions.com';


// proposal
export const insertproposal = (data) =>
    axios.post(`${baseUrl}/proposal/proposal_list`, data);
  export const getproposal = () => axios.get(`${baseUrl}/proposal/get`);
  export const deleteproposal = (id) =>
    axios.delete(`${baseUrl}/proposalDelete/${id}`);
  export const editproposal= (id, data) =>
    axios.put(`${baseUrl}/proposalEdit/${id}`, data);

  // get tier
  export const getTier = () => axios.get(`${baseUrl}/tier_management/get`);
  // fee management
export const getFeeManagement = () => axios.get(`${baseUrl}/api/fee_management/get`);



// stak
export const insertStaking = (data) =>
  axios.post(`${baseUrl}/staking/staking_list`, data);
export const getStaking = () => axios.get(`${baseUrl}/staking/get`);
export const deleteStaking = (id) =>
  axios.delete(`${baseUrl}/stakingDelete/${id}`);
export const editStaking= (id, data) =>
  axios.put(`${baseUrl}/stakingEdit/${id}`, data);

// notification
export const getNotification = () => axios.get(`${baseUrl}/notification/get`);


// get users
export const getSignupUsers = () =>
  axios.get(`${baseUrl}/signup/get`);


// get users
export const getAllFaq = () =>
  axios.get(`${baseUrl}/api/faq/get`);


// get private sale
export const getPrivateSale = () =>
  axios.get(`${baseUrl}/privateSale/get`);



// vote api
export const insertVote = (voteData) => axios.post("/api/vote", voteData);
export const getVoteData = () =>
  axios.get(`${baseUrl}/vote/get`);

export const fetchVote = (userId, proposalId) =>
  axios.get(`/api/vote/${userId}/${proposalId}`);


// get proposalIsExecuted
export const getPropoalIsExecuted = () =>
  axios.get(`${baseUrl}/proposalIsExecuted/get`);

// votes on proposal get
export const getVotesOnPropoal = () =>
  axios.get(`${baseUrl}/api/proposals-with-votes`);

// whitelist
export const getWhitelist = () =>
  axios.get(`${baseUrl}/whitelist/get`);

// get token
export const getTokens = () =>
  axios.get(`${baseUrl}/token/get`);


// donate stake
export const donateStaking = (data) =>
  axios.post(`${baseUrl}/donate-stake`, data);

export const getAllDonatonList = () =>
  axios.get(`${baseUrl}/allDonations/get/`);

// post claim
export const insertClaim = (data) =>
  axios.post(`${baseUrl}/claim-donation`, data);

