"use client"

import { useState, useEffect } from "react"
import { Modal } from "react-bootstrap"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import axios from "axios"
import ABI from "../../utils/abi"
import { blockchainTxnAddress, contractAddress } from "../../utils/utils"
import Web3 from "web3"
import { getFeeManagement, getTier } from "../../api/Api"

const ProfitableModal = (props) => {
  const [amount, setamount] = useState()
  const [isLoading, setisLoading] = useState(false)
  const [data, setdata] = useState([])
  const [data2, setdata2] = useState([])
  const [investmentSendTemplate, setInvestmentSendTemplate] = useState([])
  const [investmentReceiveTemplate, setInvestmentReceiveTemplate] = useState([])
  const [etherPrice, setEtherPrice] = useState(3085.24)
  const [tiersList, setTiersList] = useState([])
  const [totalTokensInCirculation, setTotalTokensInCirculation] = useState(0)
  const [userTokenBalance, setUserTokenBalance] = useState(0)
  const [userTier, setUserTier] = useState(null)
  const [allHolders, setAllHolders] = useState([])
  const [holdersBalances, setHoldersBalances] = useState({})
  const [totalWeight, setTotalWeight] = useState(0)
  const [holderDetails, setHolderDetails] = useState([])

  const day = new Date().getDate()
  const month = new Date().getMonth() + 1
  const year = new Date().getFullYear()

  const date = month + "/" + day + "/" + year

  const [purchasedTickets, setPurchasedTickets] = useState([])
  const [dataAPI, setDataAPI] = useState([])
  const [tier, setTier] = useState([])
  const [loading, setLoading] = useState(true)

  const getData = async () => {
    try {
      setLoading(true)
      const res = await getFeeManagement()
      const resTier = await getTier()
      console.log("API Response:", res)

      const newData = res.data?.data || []
      const newTier = resTier.data || []

      if (JSON.stringify(newData) !== JSON.stringify(dataAPI)) {
        setDataAPI(newData)
      }
      if (JSON.stringify(newTier) !== JSON.stringify(tier)) {
        setTier(newTier)
      }
    } catch (error) {
      console.error("Error fetching staking data:", error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    if (!props.show) {
      setamount("")
      setisLoading(false)
    }
  }, [props.show])

  useEffect(() => {
    const AccountInfo = localStorage.getItem("antsInformation")
    const parsedInfo = JSON.parse(AccountInfo)
    if (parsedInfo) {
      fetchPurchasedTickets(parsedInfo)
    }
  }, [])

  const fetchPurchasedTickets = (parsedInfo) => {
    axios
      .get(`${global.backendUrl}/tickets/purchase/${parsedInfo?.uid}`)
      .then((res) => {
        setPurchasedTickets(res.data)
      })
      .catch((err) => console.log(err))
  }

  // Modified to handle API rate limits and CORS issues
  function getDollarValues() {
    // Use a try-catch block to handle potential errors
    try {
      // Add cache-busting parameter and use CoinGecko API's free tier properly
      axios
        .get("https://api.coingecko.com/api/v3/coins/markets", {
          params: {
            vs_currency: "usd",
            ids: "bitcoin,ethereum",
            order: "market_cap_desc",
            per_page: 100,
            page: 1,
            sparkline: false,
            timestamp: Date.now(), // Cache busting
          },
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response.data && response.data.length > 1) {
            setEtherPrice(response.data[1].current_price)
          }
        })
        .catch((error) => {
          console.log("Error fetching crypto prices:", error)
          // Continue using the fallback price if API fails
          console.log("Using fallback ETH price:", etherPrice)
        })
    } catch (error) {
      console.log("Exception in getDollarValues:", error)
    }
  }

  // Add a throttling mechanism to prevent excessive API calls
  useEffect(() => {
    let timeoutId

    if (props.show) {
      // Only call the API when the modal is shown
      timeoutId = setTimeout(() => {
        getDollarValues()
      }, 1000) // Delay API call by 1 second
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [props.show])

  const fetchTiers = async () => {
    try {
      const response = await axios.get(`${global.backendUrl}/tiers/get`)
      if (response.data && response.data.data) {
        setTiersList(response.data.data)
      }
    } catch (error) {
      console.error("Error fetching tiers:", error)
    }
  }

  // Modify the determineANTSTier function to use the tier data from API
  const determineHolderTier = (balance) => {
    if (!tier || tier.length === 0) return null

    // Convert balance to number to ensure correct comparison
    const balanceNum = Number(balance)

    // Define the 5-tier structure based on the requirements
    const tierStructure = [
      { min: 0, max: 10000, targetTier: 1, weight: 1.0 },
      { min: 10001, max: 100000, targetTier: 2, weight: 1.05 },
      { min: 100001, max: 1000000, targetTier: 3, weight: 1.1 },
      { min: 1000001, max: 10000000, targetTier: 4, weight: 1.15 },
      { min: 10000001, max: Number.MAX_SAFE_INTEGER, targetTier: 5, weight: 1.2 },
    ]

    // Find which tier the balance falls into
    const tierInfo = tierStructure.find((t) => balanceNum >= t.min && balanceNum <= t.max)

    if (tierInfo) {
      // Find the corresponding tier from the API data or create a default one
      const apiTier = tier.find((t) => t.id === tierInfo.targetTier) || {
        id: tierInfo.targetTier,
        weight: tierInfo.weight,
        starting_token: tierInfo.min,
        ending_token: tierInfo.max,
      }

      console.log(`Token Balance: ${balanceNum} ANTS, Tier: ${tierInfo.targetTier}, Weight: ${tierInfo.weight}`)
      return apiTier
    }

    // Default to Tier 5 for very large balances
    if (balanceNum > 10000000) {
      const tier5 = tier.find((t) => t.id === 5) || {
        id: 5,
        weight: 1.2,
        starting_token: 10000001,
        ending_token: Number.MAX_SAFE_INTEGER,
      }
      console.log(`Token Balance: ${balanceNum} ANTS, Tier: 5, Weight: 1.20`)
      return tier5
    }

    // Default to Tier 1 if no tier found (should not happen)
    const tier1 = tier.find((t) => t.id === 1) || {
      id: 1,
      weight: 1.0,
      starting_token: 0,
      ending_token: 10000,
    }
    console.log(`No tier found for balance ${balanceNum}, defaulting to Tier 1`)
    return tier1
  }

  // Update fetchAllHolders to use the new determineHolderTier function
  const fetchAllHolders = async () => {
    try {
      const web3 = new Web3(window.ethereum)
      const contract = new web3.eth.Contract(ABI, contractAddress)

      // Call the getAllHolders function from the smart contract
      const holders = await contract.methods.getAllHolders().call()
      setAllHolders(holders)

      // Get balances for all holders
      const balances = {}
      const holdersByTier = {
        1: 0, // Tier 1 count
        2: 0, // Tier 2 count
        3: 0, // Tier 3 count
        4: 0, // Tier 4 count
        5: 0, // Tier 5 count
      }
      const tierTotalWeights = {
        1: 0, // Tier 1 total weight
        2: 0, // Tier 2 total weight
        3: 0, // Tier 3 total weight
        4: 0, // Tier 4 total weight
        5: 0, // Tier 5 total weight
      }

      const holderDetailsList = []
      for (const holder of holders) {
        const balance = await contract.methods.balanceOf(holder).call()
        const balanceInEther = web3.utils.fromWei(balance, "ether")
        balances[holder] = balanceInEther

        // Determine holder's tier and weight using the tier from API
        const holderTier = determineHolderTier(balanceInEther)
        const weight = holderTier ? holderTier.weight : 1
        const weightedBalance = Number.parseFloat(weight) * Number.parseFloat(balanceInEther)

        // Count holders by tier
        if (holderTier) {
          holdersByTier[holderTier.id] = (holdersByTier[holderTier.id] || 0) + 1
        }

        holderDetailsList.push({
          address: holder,
          balance: balanceInEther,
          tierId: holderTier ? holderTier.id : "N/A",
          weight: weight,
          weightedBalance: weightedBalance,
        })
      }

      // After processing all holders, calculate and log the total weight by tier
      console.log("\n--- TIER WEIGHT CALCULATION ---")
      let overallTotalWeight = 0
      Object.keys(holdersByTier).forEach((tierId) => {
        const tierCount = holdersByTier[tierId]
        // Get the dynamic weight from the API tier data
        const tierData = tier.find((t) => t.id === Number.parseInt(tierId))
        const tierWeight = tierData
          ? tierData.weight
          : tierId === "1"
            ? 1.0
            : tierId === "2"
              ? 1.05
              : tierId === "3"
                ? 1.1
                : tierId === "4"
                  ? 1.15
                  : 1.2
        const tierTotalWeight = tierCount * tierWeight
        tierTotalWeights[tierId] = tierTotalWeight
        overallTotalWeight += tierTotalWeight
        console.log(
          `Tier ${tierId}: ${tierCount} holders × ${tierWeight} weight = ${tierTotalWeight.toFixed(2)} total weight`,
        )
      })
      console.log(`Overall Total Weight: ${overallTotalWeight.toFixed(2)}`)
      console.log("--- END TIER WEIGHT CALCULATION ---\n")

      setHoldersBalances(balances)
      setTotalWeight(overallTotalWeight) // Use the overall total weight based on holder count
      setHolderDetails(holderDetailsList)

      console.log("All Holders:", holders)
      console.log("Holders Balances:", balances)
      console.log("Holders by Tier:", holdersByTier)
      console.log("Tier Total Weights:", tierTotalWeights)
      console.log("Total Weight:", overallTotalWeight)
      console.log("Holder Details (with Tiers):", holderDetailsList)
    } catch (error) {
      console.error("Error fetching all holders:", error)
    }
  }

  const fetchTokenData = async (userWalletAddress) => {
    try {
      const web3 = new Web3(window.ethereum)
      const contract = new web3.eth.Contract(ABI, contractAddress)

      // Get total supply
      const totalSupply = await contract.methods.totalSupply().call()
      setTotalTokensInCirculation(web3.utils.fromWei(totalSupply, "ether"))

      // Get user balance if wallet address is available
      if (userWalletAddress) {
        const balance = await contract.methods.balanceOf(userWalletAddress).call()
        const balanceInEther = web3.utils.fromWei(balance, "ether")
        setUserTokenBalance(balanceInEther)

        // Determine user's tier based on balance using the tier from API
        const userTierInfo = determineHolderTier(balanceInEther)
        setUserTier(userTierInfo)

        console.log("User Token Balance:", balanceInEther)
        console.log("User Tier:", userTierInfo)
      }

      // Fetch all holders after getting token data
      await fetchAllHolders()
    } catch (error) {
      console.error("Error fetching token data:", error)
    }
  }

  const calculateRewardDistribution = (feeAmount) => {
    if (!allHolders.length || totalWeight === 0) {
      return {}
    }

    // Calculate the share per unit weight
    const sharePerUnitWeight = Number.parseFloat(feeAmount) / totalWeight
    console.log("\n--- REWARD DISTRIBUTION CALCULATION ---")
    console.log(`ETH to distribute: ${feeAmount} ETH`)
    console.log(`Total Weight: ${totalWeight.toFixed(2)}`)
    console.log(`Share Per Unit Weight: ${sharePerUnitWeight.toFixed(8)} ETH per unit weight`)

    // Calculate rewards for each holder based on their tier weight
    const rewards = {}

    // Group rewards by tier for summary
    const tierRewards = {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
    }

    for (const holder of allHolders) {
      const balance = holdersBalances[holder]
      // Use the tier data from API
      const holderTier = determineHolderTier(balance)

      if (holderTier) {
        const weight = holderTier.weight
        // Holder's Reward = Holder's Weight × Share Per Unit Weight
        // For a holder in Tier 1 (weight = 1), they would receive:
        // 1 × sharePerUnitWeight = sharePerUnitWeight ETH
        const holderReward = weight * sharePerUnitWeight
        rewards[holder] = holderReward

        // Add to tier total
        tierRewards[holderTier.id] = (tierRewards[holderTier.id] || 0) + holderReward

        // Log example calculations for a few holders
        if (Object.keys(rewards).length <= 3) {
          console.log(
            `Example: Holder with ${Number.parseFloat(balance).toFixed(2)} tokens in Tier ${holderTier.id} (weight ${weight}x)`,
          )
          console.log(`Reward = ${weight} × ${sharePerUnitWeight.toFixed(8)} = ${holderReward.toFixed(8)} ETH`)
        }
      }
    }

    // Log tier reward totals
    console.log("\nRewards by Tier:")
    let totalRewardSum = 0
    Object.keys(tierRewards).forEach((tierId) => {
      const tierReward = tierRewards[tierId]
      totalRewardSum += tierReward
      console.log(`Tier ${tierId}: ${tierReward.toFixed(8)} ETH`)
    })
    console.log(`Total Rewards: ${totalRewardSum.toFixed(8)} ETH`)
    console.log("--- END REWARD DISTRIBUTION CALCULATION ---\n")

    return rewards
  }

  const handleContinue = async () => {
    console.log("purchasedTickets", purchasedTickets)
    console.log("amount", amount)
    const AccountInfo = localStorage.getItem("antsInformation")
    const parsedInfo = JSON.parse(AccountInfo)
    let tokensToAssign = "0"
    if (purchasedTickets.length > 0) {
      const ticketReturnValue = purchasedTickets[0].ticket_return
      const returnAmount = Number.parseFloat(amount) * (Number.parseFloat(ticketReturnValue) / 100)
      console.log("returnAmount", returnAmount)
      const weightage = purchasedTickets[0].ticket_weightage
      tokensToAssign = Number.parseInt(Math.round(returnAmount / weightage))
    }
    if (props.wallet_address) {
      if (props.wallet_address == "") {
        toast.error("Project owner doesn't have any associated wallet!", {
          position: toast.POSITION.TOP_RIGHT,
        })
        return
      }
    } else {
      toast.error("Project owner doesn't have any associated wallet!", {
        position: toast.POSITION.TOP_RIGHT,
      })
      return
    }
    if (!props.userId) {
      return showError("login again! There is an error")
    }
    if (!amount) {
      return showError("enter the amount")
    }

    function showError(fieldName) {
      toast.error(`Please ${fieldName}`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }

    const lessAmount = props?.amountWantRaise - data

    if (amount > lessAmount) {
      return toast.error(`You can only donate ${lessAmount}$`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }

    const userId = parsedInfo.uid
    const affiliateId_user = parsedInfo.affiliate_id || null

    let affiliateAmount = 0
    let remainingAmount = Number.parseFloat(amount)

    if (affiliateId_user) {
      try {
        const affiliateMarginRes = await axios.get(`${global.backendUrl}/api/affiliate_margin/get`)

        const affiliateMarginData = affiliateMarginRes.data.data
        if (affiliateMarginData && affiliateMarginData.margin) {
          const affiliateMargin = Number.parseFloat(affiliateMarginData.margin)

          affiliateAmount = (affiliateMargin / 100) * Number.parseFloat(amount)
          remainingAmount = Number.parseFloat(amount) - affiliateAmount

          const affiliateData = {
            userId: userId,
            affiliateId: affiliateId_user,
            project_id: props.projectId,
            value: affiliateAmount,
            project_type: "invest",
          }
          await axios.post(`${global.backendUrl}/affiliates_donate/insert`, affiliateData)
        }
      } catch (error) {
        console.error("Error fetching affiliate margin:", error)
        return showError("fetching affiliate margin")
      }
    }

    const filter = data2?.filter((t) => t.uid == props.userId && t.project_id == props.projectId)
    axios
      .get(`${global.backendUrl}/walletassocaite`)
      .then(async (resAssociation) => {
        if (resAssociation.data.length < 1) {
          toast.error("Enter amount greater than zero!", {
            position: toast.POSITION.TOP_RIGHT,
          })
          return
        }
        const wallets = [],
          percentages = []
        resAssociation.data.forEach((element) => {
          wallets.push(element.wallet_address)
          percentages.push(Number.parseFloat(element.wallet_percentage))
        })
        console.log("wallets", wallets)
        console.log("percentages", percentages)
        const dollarToEther = Number.parseFloat(remainingAmount) / etherPrice

        const web3 = new Web3(window.ethereum)
        await window.ethereum.enable()
        // Get the current account
        const accounts = await web3.eth.getAccounts()
        const account = accounts[0]
        const owner = account
        console.log("dollarToEther", dollarToEther)
        console.log("dollarToEther", dollarToEther.toFixed(14))
        const dollarToEtherString = dollarToEther.toFixed(14)
        console.log("dollarToEtherString", dollarToEtherString)
        let weiValue = 0
        weiValue = Web3.utils.toWei(dollarToEtherString, "ether")
        console.log("weiValue", weiValue)

        // Get fee percentages from API data
        const feePercentage = dataAPI.length > 0 ? dataAPI[0].feePercentage : 10 // Default to 10% if no data
        const holderPercentage = dataAPI.length > 0 ? dataAPI[0].holderPercentage : 60 // Default to 60% if no data
        const platformPercentage = 100 - holderPercentage // Calculate platform percentage

        console.log("Fee Percentage:", feePercentage)
        console.log("ANTS Holders Percentage:", holderPercentage)
        console.log("Platform Percentage:", platformPercentage)

        // Calculate actual amounts for display/logging
        const investmentAmount = Number.parseFloat(remainingAmount)
        const feeAmount = ((investmentAmount * feePercentage) / 100).toFixed(5)
        const holderAmount = ((Number.parseFloat(feeAmount) * holderPercentage) / 100).toFixed(5)
        const platformAmount = ((Number.parseFloat(feeAmount) * platformPercentage) / 100).toFixed(5)

        console.log("Investment amount:", investmentAmount, "ETH")
        console.log("Fee amount:", feeAmount, "ETH")
        console.log("ANTS Holders amount:", holderAmount, "ETH")
        console.log("Platform amount:", platformAmount, "ETH")

        // Calculate reward distribution based on the new ANTS tiers and weights
        const rewardDistribution = calculateRewardDistribution(holderAmount)

        // Inside handleContinue, update the tierDistributionData to use tier from API
        const tierDistributionData = {
          totalTokensInCirculation: totalTokensInCirculation,
          userTokenBalance: userTokenBalance,
          userTier: userTier ? userTier.id : 0,
          tiersList: tier,  
          allHolders: allHolders,
          holdersBalances: holdersBalances,
          totalWeight: totalWeight,
          rewardDistribution: rewardDistribution,
        }

        console.log("Tier Distribution Data:", tierDistributionData)
 
for (const holder of allHolders) {
  const balance = holdersBalances[holder];
  const holderTier = determineHolderTier(balance);
  
  if (holderTier && rewardDistribution[holder]) {
    const rewardAmount = rewardDistribution[holder];
    
    // Create reward claim record
    const rewardData = {
      holder_address: holder,
      reward_amount: rewardAmount,
      type: "invest"
    };
    
    // Save to database
    axios.post(`${global.backendUrl}/claim_reward/insert`, rewardData)
      .then(response => {
        console.log(`Reward saved for holder ${holder}:`, response.data);
      })
      .catch(error => {
        console.error(`Error saving reward for holder ${holder}:`, error);
      });
  }
}
        const contract = new web3.eth.Contract(ABI, contractAddress)
        console.log(contract.methods)
        const gasPrice = await web3.eth.getGasPrice()
        console.log("gasPrice", gasPrice)
        try {
          contract.methods
            .investProject(
              props.wallet_address,
              wallets,
              percentages,
              parsedInfo.wallet_address,
              tokensToAssign,
              feePercentage,
              holderPercentage,
              JSON.stringify(tierDistributionData),
            )
            .send({
              from: owner,
              gas: 2000000,
              gasPrice: gasPrice,
              value: weiValue,
            })
            .then(async (result) => {
              console.log("Transaction:", result)
              toast.success(`You donated successfully`, {
                onClose: () => props.onHide(),
              })
              props.onHide()

              if (filter.length == 0) {
                setisLoading(true)
                const formData = {
                  project_id: props.projectId,
                  uid: props.userId,
                  donation_amount: remainingAmount,
                  donation_type: props.donationType,
                  donation_date: date,
                }
                axios
                  .post(`${global.backendUrl}/donation/insert`, formData)
                  .then((response) => {
                    if (props.user_email && investmentSendTemplate.length > 0) {
                      const templateString = investmentSendTemplate[0].email_template_content
                      const finalTemplate = templateString
                        .replace("@investment_amount", "$" + remainingAmount)
                        .replace("@project_name", props.projectName)
                        .replace("@txn_hash", blockchainTxnAddress + "/" + result.transactionHash)
                      console.log("finalTemplate", finalTemplate)
                      const formDataEmail = {
                        user_email: props.user_email,
                        subject: investmentSendTemplate[0].email_template_subject,
                        message: finalTemplate,
                      }
                      axios
                        .post(`${global.backendUrl}/sendEmail`, formDataEmail)
                        .then((res) => {
                          const AccountInfo = localStorage.getItem("antsInformation")
                          const parsedInfo = JSON.parse(AccountInfo)
                          const email = parsedInfo?.email
                          if (email && investmentReceiveTemplate.length > 0) {
                            const templateStringReceive = investmentReceiveTemplate[0].email_template_content
                            const finalTemplateReceive = templateStringReceive
                              .replace("@investment_amount", "$" + remainingAmount)
                              .replace("@project_name", props.projectName)
                              .replace("@txn_hash", blockchainTxnAddress + "/" + result.transactionHash)
                            const formDataEmail = {
                              user_email: email,
                              subject: investmentReceiveTemplate[0].email_template_subject,
                              message: finalTemplateReceive,
                            }
                            axios
                              .post(`${global.backendUrl}/sendEmail`, formDataEmail)
                              .then((res) => {
                                toast.success(`Investment Sent Successfully`, {
                                  position: "top-right",
                                  autoClose: 3000,
                                  hideProgressBar: false,
                                  closeOnClick: true,
                                  pauseOnHover: true,
                                  draggable: true,
                                  progress: undefined,
                                })
                                setamount("")
                                setisLoading(false)
                                props.onHide()
                              })
                              .catch((error) => {
                                console.log(error)
                              })
                          }
                        })
                        .catch((error) => {
                          console.log(error)
                        })
                    }
                  })
                  .catch((error) => {
                    console.log(error)
                    setisLoading(false)
                  })
              } else {
                setisLoading(true)
                const newAmount = Number.parseInt(remainingAmount) + Number.parseInt(filter[0].donation_amount)
                const formData = { donation_amount: newAmount, donation_type: props.donationType, donation_date: date }
                axios
                  .put(`${global.backendUrl}/donation/update/${filter[0].donation_id}`, formData)
                  .then((response) => {
                    if (props.user_email && investmentSendTemplate.length > 0) {
                      const templateString = investmentSendTemplate[0].email_template_content
                      const finalTemplate = templateString
                        .replace("@investment_amount", "$" + remainingAmount)
                        .replace("@project_name", props.projectName)
                        .replace("@txn_hash", blockchainTxnAddress + "/" + result.transactionHash)
                      console.log("finalTemplate", finalTemplate)
                      const formDataEmail = {
                        user_email: props.user_email,
                        subject: investmentSendTemplate[0].email_template_subject,
                        message: finalTemplate,
                      }
                      axios
                        .post(`${global.backendUrl}/sendEmail`, formDataEmail)
                        .then((res) => {
                          const AccountInfo = localStorage.getItem("antsInformation")
                          const parsedInfo = JSON.parse(AccountInfo)
                          const email = parsedInfo?.email
                          if (email && investmentReceiveTemplate.length > 0) {
                            const templateStringReceive = investmentReceiveTemplate[0].email_template_content
                            const finalTemplateReceive = templateStringReceive
                              .replace("@investment_amount", "$" + remainingAmount)
                              .replace("@project_name", props.projectName)
                              .replace("@txn_hash", blockchainTxnAddress + "/" + result.transactionHash)
                            console.log("finalTemplateReceive", finalTemplateReceive)
                            const formDataEmail = {
                              user_email: email,
                              subject: investmentReceiveTemplate[0].email_template_subject,
                              message: finalTemplateReceive,
                            }
                            axios
                              .post(`${global.backendUrl}/sendEmail`, formDataEmail)
                              .then((res) => {
                                toast.success(`Investment Sent Successfully`, {
                                  position: "top-right",
                                  autoClose: 3000,
                                  hideProgressBar: false,
                                  closeOnClick: true,
                                  pauseOnHover: true,
                                  draggable: true,
                                  progress: undefined,
                                })
                                setamount("")
                                setisLoading(false)
                                props.onHide()
                              })
                              .catch((error) => {
                                console.log(error)
                              })
                          }
                        })
                        .catch((error) => {
                          console.log(error)
                        })
                    }
                  })
                  .catch((error) => {
                    console.log(error)
                    setisLoading(false)
                  })
              }
            })
            .then((error) => {
              console.log(error)
            })
            .catch((error) => console.log(error))
        } catch (error) {
          console.error("Error fetching data:", error)
          return { data: {} }
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const fetchData = () => {
    axios
      .get(`${global.backendUrl}/donation/get`)
      .then((res) => {
        setdata2(res.data)
        const filter1 = res.data.filter((t) => t.project_id == props.projectId)
        const totalDonation = filter1.reduce((total, item) => total + Number.parseInt(item.donation_amount, 10), 0)
        setdata(totalDonation)
      })
      .catch((error) => {
        console.log(error)
      })

    axios
      .get(`${global.backendUrl}/email_templates`)
      .then((res) => {
        const sentTemplates = res.data.filter((item) => item.email_template_type === "Investment Send")
        setInvestmentSendTemplate(sentTemplates)

        const receiveTemplates = res.data.filter((item) => item.email_template_type === "Investment Received")
        setInvestmentReceiveTemplate(receiveTemplates)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    if (props.show) {
      fetchData()
      fetchTiers()

      const AccountInfo = localStorage.getItem("antsInformation")
      const parsedInfo = JSON.parse(AccountInfo)
      if (parsedInfo && parsedInfo.wallet_address) {
        fetchTokenData(parsedInfo.wallet_address)
      }
    }
  }, [props.show])

  const handleAmountChange = (e) => {
    const inputValue = Number.parseFloat(e.target.value)
    if (inputValue < 0) {
      setamount(0)
    } else {
      setamount(inputValue)
    }
  }

  // Add a function to display holder information in the UI if needed
  const renderHoldersList = () => {
    if (holderDetails.length === 0) {
      return <p>No token holders found</p>
    }

    return (
      <div className="mt-3">
        <h5>Token Holders</h5>
        <div className="table-responsive">
          <table className="table table-sm">
            <thead>
              <tr>
                <th>Address</th>
                <th>Balance</th>
                <th>Tier</th>
                <th>Weight</th>
                <th>Reward Share</th>
              </tr>
            </thead>
            <tbody>
              {holderDetails.map((holder, index) => (
                <tr key={index}>
                  <td>
                    {holder.address.substring(0, 6)}...{holder.address.substring(holder.address.length - 4)}
                  </td>
                  <td>{Number.parseFloat(holder.balance).toFixed(4)}</td>
                  <td>{holder.tierId}</td>
                  <td>{holder.weight}x</td>
                  <td>{((holder.weightedBalance / totalWeight) * 100).toFixed(2)}%</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    )
  }

  // Also update the renderTierDistributionStats function to use dynamic weights:

  const renderTierDistributionStats = () => {
    if (holderDetails.length === 0) {
      return <p>No token holders found</p>
    }

    // Count holders by tier
    const tierCounts = {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
    }

    // Calculate total weight by tier
    const tierWeights = {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
    }

    holderDetails.forEach((holder) => {
      if (holder.tierId && holder.tierId !== "N/A") {
        const tierId = Number.parseInt(holder.tierId)
        tierCounts[tierId] = (tierCounts[tierId] || 0) + 1
        tierWeights[tierId] = (tierWeights[tierId] || 0) + holder.weightedBalance
      }
    })

    return (
      <div className="mt-3">
        <h5>Tier Distribution</h5>
        <div className="table-responsive">
          <table className="table table-sm">
            <thead>
              <tr>
                <th>Tier</th>
                <th>Holders</th>
                <th>Weight</th>
                <th>Total Weight</th>
                <th>% of Total</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(tierCounts).map((tierId) => {
                // Get the dynamic weight from the API tier data
                const tierData = tier.find((t) => t.id === Number.parseInt(tierId))
                const tierWeight = tierData
                  ? tierData.weight
                  : tierId === "1"
                    ? 1.0
                    : tierId === "2"
                      ? 1.05
                      : tierId === "3"
                        ? 1.1
                        : tierId === "4"
                          ? 1.15
                          : 1.2
                return (
                  <tr key={tierId}>
                    <td>{tierId}</td>
                    <td>{tierCounts[tierId]}</td>
                    <td>{tierWeight}x</td>
                    <td>{tierWeights[tierId].toFixed(2)}</td>
                    <td>{((tierWeights[tierId] / totalWeight) * 100).toFixed(2)}%</td>
                  </tr>
                )
              })}
              <tr className="font-weight-bold">
                <td colSpan={3}>Total</td>
                <td>{totalWeight.toFixed(2)}</td>
                <td>100%</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    )
  }

  const renderHolderRewards = () => {
    if (!amount || holderDetails.length === 0) {
      return null
    }

    // Calculate the share per unit weight based on the entered amount
    const sharePerUnitWeight = Number.parseFloat(amount) / totalWeight

    // Sort holders by reward (descending)
    const sortedHolders = [...holderDetails].sort(
      (a, b) => b.weight * sharePerUnitWeight - a.weight * sharePerUnitWeight,
    )

    return (
      <div className="mt-3">
        <h5>Sample Holder Rewards</h5>
        <div className="table-responsive">
          <table className="table table-sm">
            <thead>
              <tr>
                <th>Address</th>
                <th>Tier</th>
                <th>Weight</th>
                <th>Reward (ETH)</th>
              </tr>
            </thead>
            <tbody>
              {sortedHolders.slice(0, 5).map((holder, index) => {
                const reward = holder.weight * sharePerUnitWeight
                return (
                  <tr key={index}>
                    <td>
                      {holder.address.substring(0, 6)}...{holder.address.substring(holder.address.length - 4)}
                    </td>
                    <td>{holder.tierId}</td>
                    <td>{holder.weight}x</td>
                    <td>{reward.toFixed(8)}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
          <p className="text-muted small">
            Reward formula: Holder's Weight × Share Per Unit Weight ({sharePerUnitWeight.toFixed(8)} ETH)
          </p>
          <p className="text-muted small">
            Example: A Tier 1 holder (weight 1.0) receives {(1.0 * sharePerUnitWeight).toFixed(8)} ETH
          </p>
        </div>
      </div>
    )
  }

  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton></Modal.Header>
      <div className="modal-body space-y-20 pd-40">
        <h3>Donate Here</h3>
        <p className="text-center sub-heading">
          {data}$ / <span className="price color-popup">{props?.amountWantRaise}$</span>
        </p>
        <input type="number" value={amount} onChange={handleAmountChange} className="form-control mb-5" />
        {data >= props?.amountWantRaise ? (
          <button className="button-popup w-100 bg-danger">Fully Funded</button>
        ) : (
          <button className="button-popup w-100" disabled={isLoading} onClick={handleContinue}>
            Continue
          </button>
        )}
        {/* Display tier information in UI */}
        {/* {userTier && (
          <div className="mt-3">
            <h5>Your ANTS Tier Information</h5>
            <p>Balance: {Number(userTokenBalance).toLocaleString()} ANTS</p>
            <p>
              Tier: {userTier.id} (Weight: {userTier.weight}x)
            </p>
            <p>
              Range: {userTier.starting_token} - {userTier.ending_token} ANTS
            </p>
          </div>
        )} */}
        {/* {renderHoldersList()} */}
        {/* {totalWeight > 0 && (
          <div className="mt-4">
            <h5>Distribution Summary</h5>
            <p>Total Weight: {totalWeight.toFixed(2)}</p>
            <p>{amount && `Share Per Unit Weight: ${(Number(amount) / totalWeight).toFixed(8)} ETH`}</p>
            {renderTierDistributionStats()}
            {amount && renderHolderRewards()}
          </div>
        )} */}
      </div>
    </Modal>
  )
}

export default ProfitableModal

